@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;

    --radius: 0.5rem;

    --font-medium: "Graviola Soft Medium";
    --font-regular: "Graviola Soft Regular";
    --font-heavy: "Graviola Soft Heavy";
    --font-bold: "Graviola Soft Bold";

    --resume: #302056;
    --tech: #ea5448;
    --med: #e7aa22;
    --nutri: #624e92;
    --nursing: #76002a;
    --mais-vida: #be4f18;
    --psico: #be4f18;
    --training: #f57a3d;
    --parq: #be4f18;
    --physical: #5f8575;
    --physicalChecklist: #5f8575;
    --round: #624e92;

    --black: #000000;
    --purple: #3d2e61;
    --purple-dark: #302056;
    --purple-light: #624e92;
    --pink: #e86e84;
    --orange: #f57a3d;
    --orange-dark: #be4f18;
    --success: #33b6ae;
    --success-light: #42c9c1;
    --text: #a5a5a5;
    --text-light: rgba(112, 112, 112, 0.25);
    --hover-background: #ececec;
    --card-background: #f6f6f6;
    --danger-form: #ff4548;
    --danger-form: #ff4548;
    --danger: #ea5448;
    --separator-color: #d1d1d1;

    --neutral-color: #ececec;
    --neutral-low-color: #d1d1d1;
    --neutral-dark-color: #a5a5a5;
    --primary-color: #624e92;
    --primary-dark-color: #3c2e5e;
    --resume-color: #3d2e61;
    --med-color: #e7aa22;
  }

  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;

    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;

    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;

    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;

    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;

    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;

    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 212.7 26.8% 83.9%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}

@font-face {
  font-family: "Graviola Soft Medium";
  src: url("./fonts/Graviola-Soft-Regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Graviola Soft Regular";
  src: url("./fonts/Graviola-Soft-Regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Graviola Soft Heavy";
  src: url("./fonts/Graviola-Soft-Heavy.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Graviola Soft Bold";
  src: url("./fonts/Graviola-Soft-Bold.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

html,
body,
#root {
  height: 100%;
  width: 100%;
  margin: 0;
}

* {
  font-family: "Graviola Soft Regular", Roboto, Helvetica, Arial, sans-serif;
}

body.fontLoaded {
  font-family: "Graviola Soft Regular", Roboto, Helvetica, Arial, sans-serif;
}

#app {
  min-height: 100%;
  min-width: 100%;
}

.row {
  margin-bottom: 15px !important;
}
